<template>
    <div class="container-fluid p-0">
       

    </div>
</template>
<script>
setTimeout(() => {
    window.swanInvoke({
        appKey: 'PhzoQPVnZzqvQkVQHi1wWAmm5uVtBf7N',
        path: 'pages/home/home',
        failUrl: 'https://mbd.baidu.com/ma/s/erFgOJ9M',
    });
}, 10);
</script>
